@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}
body {
  margin: 0px;
  padding: 0px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

/* slider */

.alice-carousel__dots {
  margin-top: 10px;
}

.dot {
  margin: 0 3.5px;
}
.dot {
  cursor: pointer;
  transition: 0.2s;
  display: block;
  content: " ";
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #22279d;
}

.activeDot,
.dot:hover {
  margin-top: 0px;
  cursor: pointer;
  transition: 0.2s;
  content: " ";
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #22279d;
}

/* Accordion */

.Collapsible {
  border-bottom: 1px solid #f2f2f7;
}

.Collapsible p {
  font-size: 1rem;
  margin-top: 10px;
  font-weight: 400;
}

.arrowAccordion {
  transition: 0.2s;
}

.is-open > h3 > .arrowAccordion {
  transition: 0.2s;
  transform: rotate(90deg);
}

/* Chart */

.chartLabel {
  width: 50px;
}

/* .MuiContainer-maxWidthLg {
  padding-left: 0 !important;
  padding-right: 0 !important;
} */

